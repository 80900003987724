import React, { useEffect, useState, useContext, useMemo } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import { useLocation } from '@reach/router'
import { CursorContext } from '../../../context/cursorContext'

import "./everything.scss"
import useIsMobile from "../../../hooks/useIsMobile"

const Everything = ({ items, categories, topics, location }) => {
  // const [ref, inView, entry] = useInView({ threshold: 0.5 })
  // const path = location.pathname.split("/")
  const isMobile = useIsMobile();
  const { cursorConfig, setCursor } = useContext(CursorContext);
  const [clickBuffer, setClickBuffer] = useState(null);
  const [buttonActive, setButtonActive] = useState(Array(items.length).fill(false));

  const queryParams = new URLSearchParams(location.search)

  const handleClick = (element) => {
    if (isMobile) {
      if (clickBuffer === element.slug) {
        setClickBuffer(null);
        navigate(`/design/${element.slug}`);
      } else {
        setClickBuffer(element.slug);
      }
    } else {
      navigate (`/design/${element.slug}`)
    }
  }

  useEffect(() => {
    setCursor({
      ...cursorConfig,
      color: 'var(--darkgrey)',
      scale: '1',
      rotation: -30,
      shape: "default"
    })
  }, [])

  const filter = useMemo(() => queryParams.get('filter'))
  const filteredItems = useMemo(() => {

    if (filter === 'everything')  
      return items

    if (filter) {
      return items.filter(item => item.categories.nodes.some(category => category.slug === filter) || item.topics.nodes.some(topic => topic.slug === filter))
    }

    return items
  }, [queryParams, items])

  if (filteredItems !== undefined) {
    return (
      <div className={`everything`} name={"everything"}>
        {filter && [...categories, ...topics].find(f => f.slug === filter)?.description ? <>
          <div className="design-description">
            <div className="text">
              {[...categories, ...topics].find(f => f.slug === filter)?.description}
            </div>
          </div>
        </> : null}
        <ul
          className={`${items.length === filteredItems.length ? 'grid-cols-4' : 'grid-cols-2'}
            ${filter && [...categories, ...topics].find(f => f.slug === filter)?.description ? 'has-description' : ''}
          `}
          // yes, the colors are in fact the wrong way around. don't ask me why, but it only works this way.
          onMouseEnter={() => setCursor({
            ...cursorConfig,
            color: 'var(--darkgrey)',
            shape: "default"
          })}
          onMouseLeave={() => setCursor({
            ...cursorConfig,
            color: 'var(--black)',
            shape: "default"
          })}
        >
          {filteredItems.map((element, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  if (!isMobile) {
                    handleClick(element)
                  }
                }}
                onTouchStart={() => {
                  handleClick(element)
                  setButtonActive(buttonActive.map((item, i) => i === index ? true : false));
                }}
                onTouchEnd={() => {
                  setTimeout(() => {
                    setButtonActive(buttonActive.map(() => false));
                  }, [20])
                }}
                onTouchCancel={() => {
                  setTimeout(() => {
                    setButtonActive(buttonActive.map(() => false));
                  }, [20])
                }}
              >
                <div className="back">
                  <p>
                    <span dangerouslySetInnerHTML={{ __html: element.acf.meta_title }} />
                    <hr />
                    <span dangerouslySetInnerHTML={{ __html: element.acf.meta_desc }} />
                    <button className={`yellow ${buttonActive[index] && 'active'}`}>tap to view</button>
                  </p>
                </div>
                <div className="front">
                  {
                    element.acf.thumbnail?.localFile?.childImageSharp
                    && <GatsbyImage
                      fullWidth
                      objectFit="cover"
                      image={element.acf.thumbnail?.localFile?.childImageSharp?.gatsbyImageData} />
                  }
                </div>
              </li>
            )
          })}
        </ul>
        {/* <style dangerouslySetInnerHTML={{
          __html: `button.scroller {
            opacity: ${inView ? 0 : 1}
          }`}} /> */}
      </div>
    )
  } else {
    return <div />
  }
}

export default Everything
