import { graphql } from "gatsby"
import React, { useContext, useEffect } from "react"
import Everything from "../components/organisms/everything/everything"
import SEO from "../components/seo"
import { CursorContext } from '../context/cursorContext'

const EverythingPage = ({ data, location, navigate, pageContext }) => {
  const { designs } = data
  const { cursorConfig, setCursor } = useContext(CursorContext);
  const hasMouse = typeof window === "object" ? !window.matchMedia(`(hover: none)`).matches : false;

  useEffect(() => {
    if (!location.pathname.includes("design") && hasMouse) {
      navigate("/top/")
    }
  }, [])

  return (
    <div
      onMouseEnter={() => setCursor({
        ...cursorConfig,
        rotation: -30,
        scale: '1',
        color: 'var(--darkgrey)',
        shape: "default"
      })}
    >
      <SEO title="design" />
      <Everything
        items={designs.nodes}
        topics={data.topics.nodes}
        categories={data.categories.nodes}
        location={location}
        navigate={navigate}
      />
    </div>
  )
}

export default EverythingPage

export const query = graphql`
  query EverythingPage {
    designs: allWpDesign(sort: {fields: menuOrder, order: ASC}) {
      nodes {
        id
        title
        uri
        slug
        acf: designMetaFields {
          textLower
          textUpper
          thumbnail {
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          meta_title: textUpper
          meta_desc: textLower
        }
        topics {
          nodes {
            slug
          }
        }
        categories {
          nodes {
            slug
          }
        }
      }
    }
    categories: allWpCategory {
        nodes {
          id
          name
          slug
          description
          count
        }
      }
    topics: allWpTopic {
      nodes {
        id
        name
        slug
        description
        count
      } 
    }
  }
`
